* {
  
  /* scrollbar-width: thin;
  scrollbar-color: #cacaca #e8e8e8; */
}

/* Estilos gerais para o scroll */
/* ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-radius: 15px;
  color: black;
} */

/* Fundo da barra de rolagem */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
} */

/* Estilo da barra de rolagem (thumb) */
/* ::-webkit-scrollbar-thumb {
  background-color: #a6151c;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
} */

/* Estilo da borda ao passar o mouse sobre a barra */
/* ::-webkit-scrollbar-thumb:hover {
  background-color: #9c1a2c;
}

::-webkit-scrollbar-button:single-button:decrement {
  background-color: #a6151c;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: center;
} */

/* Personalizar a seta do botão para baixo */
/* ::-webkit-scrollbar-button:single-button:increment {
  background-color: #a6151c;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: center;
} */

@font-face {
  font-family: 'Museo300';
  src: url('../src/assets/font/Museo300Regular.otf') format('woff2');
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@font-face {
  font-family: 'Museo500';
  src: url('../src/assets/font/Museo700Regular.otf') format('woff2');
}

@font-face {
  font-family: 'Museo100sans';
  src: url('../src/assets/font/MuseoSans100Regular.ttf') format('woff2');
}

@font-face {
  font-family: 'Museo300sans';
  src: url('../src/assets/font/MuseoSans300Regular.ttf') format('woff2');
}

@font-face {
  font-family: 'Museo500sans';
  src: url('../src/assets/font/MuseoSans500.otf') format('woff2');
}

@font-face {
  font-family: 'Museo700sans';
  src: url('../src/assets/font/MuseoSans700.ttf') format('woff2');
}

@font-face {
  font-family: 'Museo900sans';
  src: url('../src/assets/font/MuseoSans900.ttf') format('woff2');
}

.bp-find-bar {
  top: 4rem !important;
  right: 1rem !important;
}

.bp-ControlsRoot {
  position: absolute;
  left: 85%;
  top: 20px;
  z-index: 3;
  transform: translate3d(-50%, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.bp-content,
.bcpr-PreviewMask {
  background-color: #f6f6f6;
}

.bp .bp-doc.bp-doc-document {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #cacaca #e8e8e8;
}

.bp .bp-doc.bp-doc-document::-webkit-scrollbar {
  width: 11px;
  border-radius: 0;
  background-color: #d6d6d6; 
  /* scrollbar-width: thin; */
}

.bp .bp-doc.bp-doc-document::-webkit-scrollbar-thumb {
  background: #efefef;
  border: 2px solid #d6d6d6;
  border-radius: 12px;
  /* scrollbar-width: thin; */
}

.bp .bp-doc.bp-doc-document::-webkit-scrollbar-track {
  border-radius: 22px;
  background-color: #d6d6d6;
}

/* .bp .bp-doc.bp-doc-document::-webkit-scrollbar:horizontal {
  height: 7px; /* Define a altura do scrollbar horizontal 
} */

.bp-doc .textLayer {
  max-height: 65vh;
}

.bcpr .bcpr-body {
  overflow: auto;
  @media(max-height: 600px) and (min-width: 1022px) {
    max-height: 64vh;
  }
  @media(min-height: 700px) and (min-width: 1022px) {
    max-height: 85vh;
  }
  @media(min-height: 800px) and (min-width: 1022px) {
    max-height: 85vh;
   overflow: auto;
  }
  @media(min-height: 900px) and (min-width: 1022px) {
    max-height: 100vh;
   overflow: auto;
  }
}

/* 

&::-webkit-scrollbar-thumb {
  background: #a6151c;
  border-radius: 8px;
} */
