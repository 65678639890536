.resize {
  background-color: #eee;
  width: 0.2rem;
  transition: width 0.5 ease-in, background-color 0.2 ease;
  z-index: 2;
  box-shadow: -2px 0px 7px 0px rgba(0, 0, 0, 0.03);
}

.resize:hover {
  width: 0.3rem;
  background-color: #d6d4d4;
  box-shadow: -3px 0px 8px 0px rgba(0, 0, 0, 0.1);
}
